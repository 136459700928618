import PropTypes from 'prop-types';

import {
    Provider,
    ReactReduxContext
} from 'react-redux';

import {
    configureStore
} from '@js/stores';

import ErrorBoundary from '../errors/boundary';

import MainLayout from './main';

function ApplicationLayout({
                               componentId,
                               children,
                               isHome = false
                           }) {
    return (
        <Provider store={configureStore}
                  context={ReactReduxContext}>
            <ErrorBoundary errorType="card">
                <MainLayout isHome={isHome}
                            componentId={componentId}>
                    {children}
                </MainLayout>
            </ErrorBoundary>
        </Provider>
    );
}

ApplicationLayout.propTypes = {
    componentId: PropTypes.string,
    children: PropTypes.object,
    isHome: PropTypes.bool
};

export default ApplicationLayout;
