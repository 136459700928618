import React from 'react';

import {
    manageImportError
} from '@js/actions';

export const lazyWithPreload = (factory) => {
    const Component = React.lazy(factory);
    Component.preload = factory;
    return Component;
};

// webpackChunkName cannot contain "tracking"!!!
export const HomePage = lazyWithPreload(() => import(/* webpackChunkName: "user-signup" */ '../home/home').catch(manageImportError));

export const Contact = lazyWithPreload(() => import(/* webpackChunkName: "user-signup" */ '../home/contact').catch(manageImportError));

export const UserSignup = lazyWithPreload(() => import(/* webpackChunkName: "user-signup" */ '../users/signup').catch(manageImportError));
export const UserLogin = lazyWithPreload(() => import(/* webpackChunkName: "user-login" */ '../users/login').catch(manageImportError));
export const UserShow = lazyWithPreload(() => import(/* webpackChunkName: "users-show" */ '../users/show').catch(manageImportError));
export const UserBookmarks = lazyWithPreload(() => import(/* webpackChunkName: "users-bookmarks" */ '../users/bookmarks').catch(manageImportError));
export const UserMessages = lazyWithPreload(() => import(/* webpackChunkName: "users-messages" */ '../users/messages').catch(manageImportError));
export const UserProfile = lazyWithPreload(() => import(/* webpackChunkName: "users-profile" */ '../users/profile').catch(manageImportError));
export const UserRides = lazyWithPreload(() => import(/* webpackChunkName: "users-rides" */ '../users/rides').catch(manageImportError));
export const UserShops = lazyWithPreload(() => import(/* webpackChunkName: "users-shops" */ '../users/shops').catch(manageImportError));

export const RideIndex = lazyWithPreload(() => import(/* webpackChunkName: "ride-index" */ '../rides/index').catch(manageImportError));
export const RideShow = lazyWithPreload(() => import(/* webpackChunkName: "ride-show" */ '../rides/show').catch(manageImportError));
export const RideEdit = lazyWithPreload(() => import(/* webpackChunkName: "ride-edit" */ '../rides/edit').catch(manageImportError));
export const RideNew = lazyWithPreload(() => import(/* webpackChunkName: "ride-new" */ '../rides/new').catch(manageImportError));
export const RideSearch = lazyWithPreload(() => import(/* webpackChunkName: "ride-search" */ '../rides/search').catch(manageImportError));
export const RideExplore = lazyWithPreload(() => import(/* webpackChunkName: "ride-explore" */ '../rides/explore').catch(manageImportError));
export const RideAffiliation = lazyWithPreload(() => import(/* webpackChunkName: "ride-affiliation" */ '../rides/affiliation').catch(manageImportError));

export const ShopIndex = lazyWithPreload(() => import(/* webpackChunkName: "shop-index" */ '../shops/index').catch(manageImportError));
export const ShopShow = lazyWithPreload(() => import(/* webpackChunkName: "shop-show" */ '../shops/show').catch(manageImportError));
export const ShopEdit = lazyWithPreload(() => import(/* webpackChunkName: "shop-edit" */ '../shops/edit').catch(manageImportError));
export const ShopNew = lazyWithPreload(() => import(/* webpackChunkName: "shop-new" */ '../shops/new').catch(manageImportError));
export const ShopSearch = lazyWithPreload(() => import(/* webpackChunkName: "shop-search" */ '../shops/search').catch(manageImportError));
export const ShopExplore = lazyWithPreload(() => import(/* webpackChunkName: "shop-explore" */ '../shops/explore').catch(manageImportError));
export const ShopAffiliation = lazyWithPreload(() => import(/* webpackChunkName: "shop-affiliation" */ '../shops/affiliation').catch(manageImportError));
export const ShopClaim = lazyWithPreload(() => import(/* webpackChunkName: "shop-claim" */ '../shops/claim').catch(manageImportError));

export const ProductIndex = lazyWithPreload(() => import(/* webpackChunkName: "product-index" */ '../products/index/list').catch(manageImportError));
export const ProductShow = lazyWithPreload(() => import(/* webpackChunkName: "product-show" */ '../products/show').catch(manageImportError));
export const ProductEdit = lazyWithPreload(() => import(/* webpackChunkName: "product-edit" */ '../products/edit').catch(manageImportError));
export const ProductNew = lazyWithPreload(() => import(/* webpackChunkName: "product-new" */ '../products/new').catch(manageImportError));

export const PoiShow = lazyWithPreload(() => import(/* webpackChunkName: "poi-show" */ '../pois/show').catch(manageImportError));

export const PlaceIndex = lazyWithPreload(() => import(/* webpackChunkName: "place-index" */ '../places/index').catch(manageImportError));
export const PlaceShow = lazyWithPreload(() => import(/* webpackChunkName: "place-show" */ '../places/show').catch(manageImportError));

export const SearchExplore = lazyWithPreload(() => import(/* webpackChunkName: "search-explore" */ '../search/explore').catch(manageImportError));

export const SpecialityShow = lazyWithPreload(() => import(/* webpackChunkName: "specialities-show" */ '../specialities/show').catch(manageImportError));

export const ThemeShow = lazyWithPreload(() => import(/* webpackChunkName: "themes-show" */ '../themes/show').catch(manageImportError));

export const TouristOfficesShow = lazyWithPreload(() => import(/* webpackChunkName: "tourist-offices-show" */ '../touristOffices/show').catch(manageImportError));
export const TouristOfficesNew = lazyWithPreload(() => import(/* webpackChunkName: "tourist-offices-new" */ '../touristOffices/new').catch(manageImportError));
export const TouristOfficesEdit = lazyWithPreload(() => import(/* webpackChunkName: "tourist-offices-edit" */ '../touristOffices/edit').catch(manageImportError));

export const BlogShow = lazyWithPreload(() => import(/* webpackChunkName: "blog-show" */ '../blogs/blog').catch(manageImportError));
export const BlogArticleShow = lazyWithPreload(() => import(/* webpackChunkName: "blog-article-show" */ '../blogs/article').catch(manageImportError));

export const AdminBenchmark = lazyWithPreload(() => import(/* webpackChunkName: "admins-benchmark" */ '../admin/managers/benchmark').catch(manageImportError));
export const AdminComments = lazyWithPreload(() => import(/* webpackChunkName: "admins-comments" */ '../admin/managers/comments').catch(manageImportError));
export const AdminCompetitor = lazyWithPreload(() => import(/* webpackChunkName: "admins-competitor" */ '../admin/managers/competitor').catch(manageImportError));
export const AdminContacts = lazyWithPreload(() => import(/* webpackChunkName: "admins-contacts" */ '../admin/managers/contacts').catch(manageImportError));
export const AdminContest = lazyWithPreload(() => import(/* webpackChunkName: "admins-contest" */ '../admin/managers/contest').catch(manageImportError));
export const AdminExpense = lazyWithPreload(() => import(/* webpackChunkName: "admins-expense" */ '../admin/managers/expense').catch(manageImportError));
export const AdminLogs = lazyWithPreload(() => import(/* webpackChunkName: "admins-logs" */ '../admin/managers/logs').catch(manageImportError));
export const AdminMedia = lazyWithPreload(() => import(/* webpackChunkName: "admins-media" */ '../admin/managers/media').catch(manageImportError));
export const AdminOpenData = lazyWithPreload(() => import(/* webpackChunkName: "admins-open-data" */ '../admin/managers/openData').catch(manageImportError));
export const AdminPlaces = lazyWithPreload(() => import(/* webpackChunkName: "admins-places" */ '../admin/managers/places').catch(manageImportError));
export const AdminPois = lazyWithPreload(() => import(/* webpackChunkName: "admins-pois" */ '../admin/managers/pois').catch(manageImportError));
export const AdminProductData = lazyWithPreload(() => import(/* webpackChunkName: "admins-product-data" */ '../admin/managers/productData').catch(manageImportError));
export const AdminProducts = lazyWithPreload(() => import(/* webpackChunkName: "admins-products" */ '../admin/managers/products').catch(manageImportError));
export const AdminRideData = lazyWithPreload(() => import(/* webpackChunkName: "admins-ride-data" */ '../admin/managers/rideData').catch(manageImportError));
export const AdminRides = lazyWithPreload(() => import(/* webpackChunkName: "admins-rides" */ '../admin/managers/rides').catch(manageImportError));
export const AdminSeo = lazyWithPreload(() => import(/* webpackChunkName: "admins-seo" */ '../admin/managers/seo').catch(manageImportError));
export const AdminServer = lazyWithPreload(() => import(/* webpackChunkName: "admins-server" */ '../admin/managers/server').catch(manageImportError));
export const AdminSettings = lazyWithPreload(() => import(/* webpackChunkName: "admins-settings" */ '../admin/managers/settings').catch(manageImportError));
export const AdminShopData = lazyWithPreload(() => import(/* webpackChunkName: "admins-shop-data" */ '../admin/managers/shopData').catch(manageImportError));
export const AdminShops = lazyWithPreload(() => import(/* webpackChunkName: "admins-shops" */ '../admin/managers/shops').catch(manageImportError));
export const AdminTouristOffices = lazyWithPreload(() => import(/* webpackChunkName: "admins-tourist-offices" */ '../admin/managers/touristOffices').catch(manageImportError));
export const AdminUser = lazyWithPreload(() => import(/* webpackChunkName: "admins-user" */ '../admin/managers/user').catch(manageImportError));
export const AdminUsers = lazyWithPreload(() => import(/* webpackChunkName: "admins-users" */ '../admin/managers/users').catch(manageImportError));
export const AdminRideImporter = lazyWithPreload(() => import(/* webpackChunkName: "admins-users" */ '../admin/importers/rides').catch(manageImportError));
export const AdminShopImporter = lazyWithPreload(() => import(/* webpackChunkName: "admins-users" */ '../admin/importers/shops').catch(manageImportError));
