import React from 'react';
import PropTypes from 'prop-types';

import I18n from '@js/modules/translations';

import {
    pushError
} from '@js/actions/errorActions';

export default class ErrorBoundary extends React.Component {
    static propTypes = {
        children: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.array
        ]).isRequired,
        errorType: PropTypes.oneOf(['text', 'card']),
        errorTitle: PropTypes.string,
        errorMessage: PropTypes.string,
        className: PropTypes.string
    };

    static defaultProps = {
        errorType: 'card'
    };

    constructor(props) {
        super(props);
    }

    state = {
        hasError: false
    };

    componentDidCatch(error) {
        this.setState({
            hasError: true
        });

        if (process.env.NODE_ENV !== 'production') {
            window.log_on_screen([error.message, ...error.stack.slice(0, 200).split('<')]);
        }

        pushError({
            ...error,
            trace: error.stack,
            origin: 'client'
        });
    }

    render() {
        if (this.state.hasError) {
            if (this.props.errorType === 'text') {
                return (
                    <h3 className={this.props.className}
                        style={{
                            margin: '1rem 0',
                            textAlign: 'center'
                        }}>
                        {this.props.errorTitle || I18n.t('js.helpers.errors.boundary.title')}
                    </h3>
                );
            } else {
                return (
                    <div className="card-panel">
                        <h3 className={this.props.className}
                            style={{
                                margin: '1rem 0',
                                textAlign: 'center'
                            }}>
                            {this.props.errorTitle || I18n.t('js.helpers.errors.boundary.title')}
                        </h3>

                        <p style={{
                            margin: '2rem',
                            fontStyle: 'italic',
                            textAlign: 'center'
                        }}>
                            {this.props.errorMessage || I18n.t('js.helpers.errors.boundary.message')}
                        </p>
                    </div>
                );
            }
        } else {
            return this.props.children;
        }
    }
}
