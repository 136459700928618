import React, {
    Suspense
} from 'react';
import PropTypes from 'prop-types';

import {
    convertJsonApi
} from '../../middlewares/json';

import * as RouteComponents from '../loaders/components';

export const extractDataFromElement = (elementId, extractAndRemove = true) => {
    const componentElement = document.getElementById(elementId);
    const componentData = {};

    if (!componentElement || !componentElement.attributes) {
        return componentData;
    }

    [].forEach.call(componentElement.attributes, function (componentAttribute) {
        if (/^data-/.test(componentAttribute.name)) {
            const dataName = componentAttribute.name.substr(5)
                .replace(/-(.)/g, function ($0, $1) {
                    return $1.toUpperCase();
                });
            componentData[dataName] = componentAttribute.value.startsWith('{') || componentAttribute.value.startsWith('[') ? JSON.parse(componentAttribute.value) : componentAttribute.value;
            if (Array.isArray(componentData[dataName]) || typeof componentData[dataName] === 'object') {
                componentData[dataName] = convertJsonApi(componentData[dataName]);
                if (componentData[dataName][dataName]) {
                    componentData[dataName] = componentData[dataName][dataName];
                }
            }
        }
    });

    if (extractAndRemove) {
        Object.keys(componentData)
            .filter((componentKey) => componentKey !== 'component')
            .forEach((componentKey) => delete componentElement.dataset[componentKey]);
    }

    return componentData;
};

export default class MainLayout extends React.Component {
    static propTypes = {
        componentId: PropTypes.string,
        children: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.object,
            PropTypes.string
        ]),
        isHome: PropTypes.bool
    };

    static defaultProps = {
        isHome: false
    };

    constructor(props) {
        super(props);
    }

    shouldComponentUpdate() {
        return false;
    }

    _renderComponent = () => {
        const {
            component,
            ...componentData
        } = extractDataFromElement(this.props.componentId, !window.currentAdminId);

        const Component = RouteComponents[component];

        if (!component) {
            throw new Error('Component not declared for this page!');
        }

        return (
            <Suspense fallback={<div/>}>
                <Component {...componentData}/>
            </Suspense>
        );
    };

    render() {
        return (
            <main>
                <Suspense fallback={<div/>}>
                    <div>
                        {
                            this.props.children
                                ?
                                <div dangerouslySetInnerHTML={{__html: this.props.children}}/>
                                :
                                this._renderComponent()
                        }
                    </div>
                </Suspense>
            </main>
        );
    }
}
